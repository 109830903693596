import { MaskInput } from "maska";
import JustValidate from 'just-validate';



document.addEventListener('DOMContentLoaded', function () {

    const body = document.body;
    const OS = navigator.platform;

    function showDialog(block) {
        block.show();
        document.body.style.overflow = "hidden";
        if (OS === "Win32" && window.innerWidth >= 1236) {
            body.style.paddingRight = "17px";
        }
    }

    function hideDialog(block) {
        block.close();
        document.body.style.overflow = "visible";
        body.style.paddingRight = "0px";
        document.querySelector('.input-hidden-id').value = "";
    }

    const burger = document.querySelector('.header__burger');
    const mobileMenu = document.querySelector('.header__menu_mobile');
    if (burger && mobileMenu) {
        burger.addEventListener('click', () => {
            burger.classList.toggle('is-active');
            mobileMenu.classList.toggle('is-active');
            if (burger.classList.contains('is-active')) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        })
    }

    //модальное окно обратной связи
    const feedbackModal = document.querySelector('.feedback-modal__position');
    const feedbackModalClose = document.querySelector('.feedback-modal__close');
    const feedbackModalDialogContent = document.querySelector('.feedback-modal-content');

    feedbackModalClose.addEventListener('click', function () {
        hideDialog(feedbackModal);
    });

    feedbackModalDialogContent.addEventListener('click', function (e) {
        console.log(e.target);
        const target = e.target;
        if (target.closest('.feedback-modal-content')) {
            return;
        }
        hideDialog(feedbackModal);
    });

    //открытие окна обратной связи с шапки
    const feedbackButton = document.querySelector('.header__call');


    feedbackButton.addEventListener('click', function () {
        showDialog(feedbackModal);
    });

    //открытие окна обратной связи с кнопки звонка
    const callButton = document.querySelector('.call');

    callButton.addEventListener('click', function () {
        showDialog(feedbackModal);
    });

    //открытие окна обратной связи с кнопки звонка в контактах
    if(document.querySelector('.contacts__content_buttons_call')) {

        const contactsCallButtons = document.querySelectorAll('.contacts__content_buttons_call');

        contactsCallButtons.forEach(function (contactsCallButton) {
            contactsCallButton.addEventListener('click', function (e) {
                showDialog(feedbackModal);
            });
        });
    }

    //модальное окно загрузки
    const feedbackModalCreate = document.querySelector('.feedback-modal__create_position');
    const loadingImg = document.querySelector('.feedback-modal__create_img');


    //модальное окно завершения отправки заявки
    const feedbackModalEnd = document.querySelector('.feedback-modal__end_position');
    const feedbackModalEndClose = document.querySelector('.feedback-modal__end_close');
    const feedbackModalEndDialogContent = document.querySelector('.feedback-modal__end-content');

    feedbackModalEndClose.addEventListener('click', function () {
        hideDialog(feedbackModalEnd);
    });

    feedbackModalEndDialogContent.addEventListener('click', function (e) {
        console.log(e.target);
        const target = e.target;
        if (target.closest('.feedback-modal-content')) {
            return;
        }
        hideDialog(feedbackModalEnd);
    });

    //маска на телефоны
    const feedbackPhones = document.querySelectorAll('.feedback-tel');

    feedbackPhones.forEach(function (phone) {
        new MaskInput(phone);

        phone.addEventListener('input', function () {
            if (phone.value === '+7 (8') {
                phone.value = '+7';
            }
        });
    });

    //валидация форм
    const forms = document.querySelectorAll('.form-feedback');

    forms.forEach(function (form) {

        const validator = new JustValidate(form, {
            errorFieldCssClass: 'is-input-invalid',
            errorFieldStyle: {
                border: '1px solid #FF0000',
            },
            errorLabelCssClass: 'is-label-invalid',
            focusInvalidField: false,
            lockForm: true,
            submitFormAutomatically: false,
        });

        validator
            .addField('.feedback-name',
                [
                    {
                        rule: 'required',
                        errorMessage: 'Вы не заполнили данное поле',
                    },
                ],
            )
            .addField('.feedback-tel',
                [
                    {
                        rule: 'required',
                        errorMessage: 'Вы не заполнили данное поле',
                    },
                    {
                        rule: 'minLength',
                        value: 18,
                        errorMessage: 'Введите корректный номер телефона',
                    },
                ],
            )

        form.addEventListener('submit', function (e) {
            if (form.checkValidity()) {
                e.preventDefault();
                createFeedback();
            }
        });

        function createFeedback() {
            // hideDialog(productOrder);
            // showDialog(orderCreatePosition);

            let id = 0;

            if (document.querySelector('.input-hidden-id')) {
                id = document.querySelector('.input-hidden-id').value
            }

            const data = {
                'id': id,
                'name': document.querySelector('.feedback-name').value,
                'telephone': document.querySelector('.feedback-tel').value,
            };

            const responseBody = JSON.stringify(data);

            //
            // //ЗАГЛУШКА ПРОВЕРКИ ОТПРАВКИ
            // if(feedbackModal.hasAttribute('open')) {
            //     hideDialog(feedbackModal);
            // }
            //
            // showDialog(feedbackModalCreate);
            // loadingImg.classList.add('loading-img');
            // setTimeout(() => {
            //     hideDialog(feedbackModalCreate);
            //     document.querySelector('.feedback-modal__end_title').innerText = "Ошибка загрузки!" //  response.data;
            //     document.querySelector('.feedback-modal__end_text').innerText = "Произошла ошибка" // e;
            //     showDialog(feedbackModalEnd);
            // }, 3000);

            try {
                fetch('/api/feedback/callRequest/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: responseBody
                }).then(response => {
                    return response.json();
                }).then(response => {
                    if(feedbackModal.hasAttribute('open')) {
                        hideDialog(feedbackModal);
                    }
                    console.log(response);
                    document.querySelector('.feedback-modal__end_title').innerText = "Спасибо!" //  response.data;
                    document.querySelector('.feedback-modal__end_text').innerText = "Заявка успешно отправлена, мы перезвоним вам в ближайшее время!" //  response.data;
                    showDialog(feedbackModalEnd);
                }).catch(response => {
                    if(feedbackModal.hasAttribute('open')) {
                        hideDialog(feedbackModal);
                    }
                        document.querySelector('.feedback-modal__end_title').innerText = "Ошибка загрузки!" //  response.data;
                        document.querySelector('.feedback-modal__end_text').innerText = "Произошла ошибка" // e;
                        showDialog(feedbackModalEnd);
                    }

                );
            } catch (e) {
                if(feedbackModal.hasAttribute('open')) {
                    hideDialog(feedbackModal);
                }
                document.querySelector('.feedback-modal__end_title').innerText = "Ошибка загрузки!" //  response.data;
                document.querySelector('.feedback-modal__end_text').innerText = "Произошла ошибка" // e;
                showDialog(feedbackModalEnd);
            }

        }


    });

    //открытие окна обратной связи для заказов
    if(document.querySelector('.button-order')) {
        const orderFeedbackButtons = document.querySelectorAll('.button-order');

        orderFeedbackButtons.forEach(function (button) {
            button.addEventListener('click', function () {
                try {
                    document.querySelector('.input-hidden-id').value = button.getAttribute('data-id');
                } catch (e) {
                    console.log(e.message);
                }
                showDialog(feedbackModal);
            });
        });
    }
});

